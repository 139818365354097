.container {
    display: flex;                  /* establish flex container */
    flex-direction: row;            /* default value; can be omitted */
    flex-wrap: nowrap;              /* default value; can be omitted */
    justify-content: space-between; /* switched from default (flex-start, see below) */
}

.content {
    width: 100px;
    height: 100px;
    border: 2px dashed red;
}

.navMenu {
    margin-top: 2px;
}

.accountInfo {
    color: white;
}

.float-right {
    margin-right: 3%;
    float: right;
}

.float-left {
    margin-left: 3%;
    float: left;
}

.last-elem {
    margin-right: 10%;
}
