#container {
    width:100%;
    text-align:center;
    margin-top: 2%;
    padding-right: 2%;
    padding-left: 2%;
}

#left {
    float:left;
    width: 100px;
}

#center {
    display: inline-block;
    margin:0 auto;
    width: 100px;
}

#right {
    float:right;
    width: 100px;
}
